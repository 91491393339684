import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LangCode } from './core/types/langCode.enum';
import { combineLatest, Subscription } from 'rxjs';
import { ConfigurationService } from './services/configuration.service';
import { StorageService } from './services/storage.service';
import { ConfigResponse } from './core/types/config-response.type';
import { CustomError } from './core/types/error.type';
import { HeaderComponent } from './components/header/header.component';
import { translateLangCode } from './utils';
import { FooterComponent } from './components/footer/footer.component';
import { environment } from '../environments/environment';

@Component({
  standalone: true,
  imports: [RouterModule, TranslateModule, HeaderComponent, FooterComponent],
  selector: 'app-root',
  providers: [ConfigurationService, StorageService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  webRoot = environment.WEB_ROOT;
  subscription: Subscription = new Subscription();

  //propertyCode = TenantId + propertyId
  propertyCode!: string;
  langCode!: LangCode;
  error!: CustomError;
  config!: ConfigResponse;
  isNoDataErrorPage!: boolean;
  dayInMilliseconds: number = 86400000;

  constructor(
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private storageService: StorageService,
    private configurationService: ConfigurationService,
    private router: Router
  ) {
    this.translateService.use(LangCode.ItIT);
  }

  ngOnInit() {
    this.subscribeToParamsChange();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscribeToParamsChange() {
    this.subscription.add(
      combineLatest(this.configurationService.langCode$, this.configurationService.propertyCode$).subscribe(
        ([langCode, propertyCode]) => {
          if (
            langCode &&
            propertyCode &&
            (this.langCode !== translateLangCode(langCode) || this.propertyCode !== propertyCode)
          ) {
            if (!Object.values(LangCode).includes(langCode as LangCode)) langCode = LangCode.ItIT;
            this.langCode = translateLangCode(langCode);
            this.propertyCode = propertyCode;
            this.initConfig();
          }
        }
      )
    );
  }

  initConfig() {
    const configFromStorage = this.getConfigFromStorage();
    const langCodeFromConfig = configFromStorage?.property.langCode;
    const propertyCodeFromConfig = configFromStorage?.property.propertyCode;
    if (
      configFromStorage &&
      langCodeFromConfig === (this.langCode as string) &&
      propertyCodeFromConfig === this.propertyCode
    ) {
      this.handleConfigSuccess(configFromStorage);
    } else {
      this.fetchConfig();
    }
  }

  getConfigFromStorage() {
    const todayTimestamp = new Date().getTime();
    const config = this.storageService.readFromSessionStorage('config-' + this.propertyCode);
    const configTimeStamp = this.storageService.readFromSessionStorage('configTimestamp-' + this.propertyCode);
    if (config && configTimeStamp && todayTimestamp - JSON.parse(configTimeStamp) < this.dayInMilliseconds) {
      return JSON.parse(config);
    } else {
      return null;
    }
  }

  private fetchConfig() {
    this.configurationService.getConfig(this.propertyCode, this.langCode).subscribe({
      next: (config) => {
        this.handleConfigSuccess(config);
      },
      error: (error) => {
        this.handleConfigError(error);
      },
      complete: () => {
        if (!this.config) this.router.navigate(['no-data-error'], { skipLocationChange: true, replaceUrl: false });
      },
    });
  }

  private handleConfigSuccess(config: ConfigResponse) {
    this.writeConfigToStorage(config);
    this.getImageDataURL(config.property.logoUrl, this.writeLogoToStorage.bind(this), console.error);
  }

  private writeLogoToStorage(dataUrl: string) {
    this.storageService.writeToSessionStorage('logoDataUrl', dataUrl);
  }

  private getImageDataURL(url: string, success: (data: string) => void, error: (arg0: unknown) => void) {
    const img = new Image();
    img.setAttribute('crossorigin', 'anonymous');
    img.onload = () => this.onImageLoad(img, success, error);
    img.onerror = () => error('Error loading logo IMG');
    img.src = url;
  }

  private onImageLoad(img: HTMLImageElement, success: (data: string) => void, error: (arg0: unknown) => void) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      error('Could not get the canvas context');
      return;
    }
    if (img.width && img.height) ctx.drawImage(img, 0, 0);
    this.tryCanvasToDataURL(canvas, success, error);
  }

  private tryCanvasToDataURL(
    canvas: HTMLCanvasElement,
    success: (data: string) => void,
    error: (arg0: unknown) => void
  ) {
    try {
      const data = canvas.toDataURL();
      success(data);
    } catch (e) {
      error(e);
    }
  }

  handleConfigError(error: Error) {
    console.error(error);
  }

  private writeConfigToStorage(config: ConfigResponse) {
    const todayTimestamp = new Date().getTime();
    this.storageService.writeToSessionStorage('configTimestamp-' + this.propertyCode, JSON.stringify(todayTimestamp));
    this.storageService.writeToSessionStorage('langCode', JSON.stringify(this.langCode));
    this.storageService.writeToSessionStorage('config-' + this.propertyCode, JSON.stringify(config));
    this.config = config;
    this.configurationService.setConfig(config);
  }
}
