@if (privacyItem) { @if (privacyItem.type ==='text') {
<div class="container mt-5">
  <div class="row">
    <div class="col px-0">
      <div
        class="overflow-auto scrollable-div px-3 mb-3"
        tabindex="0"
        data-result-detail="main-privacy-text"
      >
        {{ privacyItem.text }}
      </div>
    </div>
  </div>
</div>
}@else {
<div class="card d-flex flex-column my-3">
  <div class="d-flex flex-row justify-content-center mt-3">
    @for (option of privacyItem.options; track $index) {
    <div class="ms-3 me-1">
      <input
        *ngIf="!isForPdf"
        [checked]="checkIfSelected(option)"
        (change)="
          onChangeControl(
            privacyItem.id,
            option.id,
            option.value,
            option.label,
            privacyItem.id,
            privacyItem.type
          )
        "
        class="form-check-input"
        [type]="privacyItem.type"
        [id]="privacyItem.id + '-' + option.id"
        [name]="
          privacyItem.type === 'radio'
            ? privacyItem.id + '-0'
            : privacyItem.id + '-' + option.id
        "
        [value]="option.value"
        [required]="privacyItem.required"
        data-input="privacy-item-input"
      />
      <div *ngIf="isForPdf && checkIfSelected(option)">
        &#9989;
      </div>
    </div>
    <div class="me-2">
      <label class="fs-6" [for]="privacyItem.id + '-' + option.id">{{
        option.label
      }}</label>
    </div>
    }
  </div>
  <div class="mb-3 p-3" data-result-detail="privacy-item">
    {{ privacyItem.text }}
  </div>
</div>
} }
