import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrivacyItem } from '../../core/types/privacy-item.type';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ConfigurationService } from '../../services/configuration.service';
import { PrivacyItemOption } from '../../core/types/privacy-item-option.type';

@Component({
  selector: 'app-info-confirmation-box',
  templateUrl: './info-confirmation-box.component.html',
  styleUrls: ['./info-confirmation-box.component.css'],
  imports: [ReactiveFormsModule, CommonModule],
  standalone: true,
})
export class InfoConfirmationBoxComponent implements OnInit {
  @Input() id!: number;
  @Input() privacyItem!: PrivacyItem;
  @Input() form!: FormGroup;
  @Input() isForPdf = false;
  @Input() privacyItemIndex!: number;
  @Output() isSelected = new EventEmitter<number>();
  isCollapsed = false;
  selectedValues: { optionId: number; optionValue: string }[] = [];

  constructor(private configurationService: ConfigurationService) {}

  ngOnInit(): void {
    this.configurationService.langCode$.subscribe(() => {
      this.selectedValues = [];
    });
  }

  checkIfSelected(option: PrivacyItemOption) {
    return this.selectedValues.some((value) => value.optionId === option.id && value.optionValue === option.value);
  }

  onChangeControl(
    privacyItemId: number,
    optionId: number,
    optionValue: string,
    optionLabel: string,
    propertyId: number,
    type: string
  ): void {
    let privacyItemsControlValues = this.form.get('acceptedPrivacyItems')?.value;
    privacyItemsControlValues = [
      ...privacyItemsControlValues.filter((option: { propertyId: number }) => option.propertyId !== propertyId),
    ];
    const option = {
      type: type,
      privacyItemId: privacyItemId,
      propertyId: propertyId,
      id: optionId,
      value: optionValue,
      label: optionLabel,
    };
    this.selectedValues.push({ optionId, optionValue });
    privacyItemsControlValues.push(option);
    this.form.get('acceptedPrivacyItems')?.setValue(privacyItemsControlValues);
    this.form.get('acceptedPrivacyItems')?.markAsDirty();
    this.form.get('acceptedPrivacyItems')?.markAsTouched();
    this.isSelected.emit(this.id);
  }
}
